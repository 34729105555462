import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import { PDFHeader } from './PDFHeader';
import { PDFQuestionRenderer } from './PDFQuestionRenderer';
import { PDFSectionRenderer } from './PDFSectionRenderer';
import { PDFMediaRenderer } from './PDFMediaRenderer';
import { PDFSignatureRenderer } from './PDFSignatureRenderer';
import { PDFRepeatableRenderer } from './PDFRepeatableRenderer'; // Add this import

// Define the extended type for jsPDF with autoTable
interface ExtendedJsPDF extends jsPDF {
  autoTable: (options: any) => any;
  lastAutoTable?: { finalY: number };
}

export interface PDFDocumentProps {
  formId: string;
  form: any;
  responses: { [key: string]: any };
  repeatableItems: { [key: string]: Array<{ [key: string]: any }> };
  signatures: { [key: string]: string };
  imagePreview: { [key: string]: string };
  annotatedImages: { [key: string]: string };
  logoUrl: string | null;
  theme: any;
  submissionId: string; // Add this parameter
}

export const PDFDocument = ({
  formId,
  form,
  responses,
  repeatableItems,
  signatures,
  imagePreview,
  annotatedImages,
  logoUrl,
  theme,
}: PDFDocumentProps) => {
  // Helper function for safe text rendering
  const safeText = (value: any): string => {
    if (value === undefined || value === null) return '';
    return value.toString(); // Convert any value to string
  };

  // Create PDF with professional design but optimize for file size
  const doc = new jsPDF({
    compress: true, // Enable PDF compression
    putOnlyUsedFonts: true // Only embed used fonts
  }) as ExtendedJsPDF;
  
  // Set document properties
  doc.setProperties({
    title: form.formTitle || "Filled Form",
    subject: "Form Submission",
    creator: "Form Creator Tool"
  });
  
  // Define colors
  const primaryColor = theme.palette.primary.main;
  const secondaryColor: [number, number, number] = [33, 33, 33]; // #212121 - dark gray
  const lightGrayColor: [number, number, number] = [224, 224, 224]; // #e0e0e0 - light gray
  
  // Convert primary color to RGB
  const primaryColorRgb: [number, number, number] = [
    parseInt(primaryColor.substring(1, 3), 16),
    parseInt(primaryColor.substring(3, 5), 16),
    parseInt(primaryColor.substring(5, 7), 16)
  ];

  // Convert secondary color if available
  const secondaryColorRgb: [number, number, number] = theme.palette.secondary ? [
    parseInt(theme.palette.secondary.main.substring(1, 3), 16),
    parseInt(theme.palette.secondary.main.substring(3, 5), 16),
    parseInt(theme.palette.secondary.main.substring(5, 7), 16)
  ] : [33, 33, 33]; // Default secondary color

  const blackTextColor: [number, number, number] = [0, 0, 0]; // Simple black for text
  
  // Define initial Y position for content
  let currentY = 0;

  // Add header content
  currentY = PDFHeader({
    doc,
    form,
    formId,
    responses,
    logoUrl,
    primaryColorRgb,
    lightGrayColor,
    blackTextColor
  });
  
  // Get all questions excluding instructions
  const questionsToInclude = form.questions.filter((q: { type: string }) => q.type !== "instructions");

  // Initialize counters for sections and questions
  let sectionCounter = 1;
  let questionCounter = 1;

  // Add section title for responses
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.setDrawColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.roundedRect(10, currentY, doc.internal.pageSize.getWidth() - 20, 10, 2, 2, "FD");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(12);
  doc.text("RESPONSES", doc.internal.pageSize.getWidth() / 2, currentY + 7, { align: "center" });
  currentY += 15;
  doc.setTextColor(0, 0, 0);
  
  // Process each question or section
  questionsToInclude.forEach((q: any, index: number) => {
    // Check if we need a new page
    if (currentY > doc.internal.pageSize.getHeight() - 40) {
      doc.addPage();
      currentY = 20;
    }
    
    // Handle sections using the PDFSectionRenderer
    if (q.type === "section") {
      // Process section with all its questions
      currentY = PDFSectionRenderer({
        doc,
        section: q,
        sectionCounter,
        questionCounter,
        currentY,
        responses,
        repeatableItems,
        signatures,
        imagePreview,
        annotatedImages,
        primaryColorRgb,
        blackTextColor,
        lightGrayColor,
        safeText
      });
      
      sectionCounter++;
      // We already processed section questions in the section renderer
      return;
    }
    
    // Handle regular questions using the PDFQuestionRenderer
    currentY = PDFQuestionRenderer({
      doc,
      question: q,
      questionIndex: index,
      questionCounter,
      sectionCounter: 0, // For regular questions outside sections
      currentY,
      responses,
      repeatableItems,
      imagePreview,
      annotatedImages,
      signatures,
      blackTextColor,
      lightGrayColor,
      primaryColorRgb,
      safeText
    });
    
    // Increment question counter
    questionCounter++;
  });

  // Ensure we include section questions in media/signatures sections
  const allQuestions = questionsToInclude.flatMap((q: any) => {
    if (q.type === "section" && q.sectionQuestions && Array.isArray(q.sectionQuestions)) {
      return q.sectionQuestions;
    }
    return q;
  });

  // Add repeatable items section - NEW CODE
  currentY = PDFRepeatableRenderer({
    doc,
    questionsToInclude: allQuestions,
    responses,
    repeatableItems,
    currentY,
    primaryColorRgb,
    blackTextColor,
    lightGrayColor,
    safeText
  });

  // Add media sections (images)
  currentY = PDFMediaRenderer({
    doc,
    questionsToInclude: allQuestions,
    responses,
    currentY,
    primaryColorRgb,
    blackTextColor,
    safeText
  });
  
  // Add signatures section
  currentY = PDFSignatureRenderer({
    doc,
    questionsToInclude: allQuestions,
    responses,
    signatures,
    currentY, 
    primaryColorRgb, 
    blackTextColor,
    safeText
  });
  
  // Add footer with page numbers
  const pageCount = doc.internal.pages.length - 1;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(8);
    doc.setTextColor(150, 150, 150);
    doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10, { align: "right" });
  }
  
  return doc;
};