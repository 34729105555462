import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Box, Typography, Button, Divider, CircularProgress,
  TextField, IconButton, Chip, useTheme
} from '@mui/material';
import { Close, CheckCircle, Cancel, Event } from '@mui/icons-material';
import { format } from 'date-fns';
import { getApprovalSubmissionDetails } from '../../../services/firebase/approvalService';
import { updateApprovalStatus } from '../../../services/firebase/approvalService';
import { sendApprovalNotification } from '../services/approvalNotifications';
import { getStatusColor } from '../services/approvalUtils';

interface EventDetailsModalProps {
  open: boolean;
  onClose: () => void;
  event: any | null; // The selected calendar event
  onStatusChange?: () => void; // Callback when status changes
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({
  open,
  onClose,
  event,
  onStatusChange
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [comments, setComments] = useState('');
  const [submissionDetails, setSubmissionDetails] = useState<any | null>(null);
  
  useEffect(() => {
    if (open && event) {
      loadSubmissionDetails();
    } else {
      setSubmissionDetails(null);
      setComments('');
      setError(null);
      setSuccess(null);
    }
  }, [open, event]);
  
  const loadSubmissionDetails = async () => {
    if (!event?.extendedProps?.submissionId) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const details = await getApprovalSubmissionDetails(event.extendedProps.submissionId);
      setSubmissionDetails(details.submission);
      
      // Pre-fill comments if available
      if (details.submission.approvalComments) {
        setComments(details.submission.approvalComments);
      }
    } catch (err) {
      console.error("Error loading submission details:", err);
      setError("Failed to load submission details.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleApprove = async () => {
    if (!event?.extendedProps?.submissionId) return;
    
    setProcessing(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Update approval status
      const success = await updateApprovalStatus(
        event.extendedProps.submissionId,
        'approved',
        comments
      );
      
      if (!success) {
        throw new Error("Failed to approve submission");
      }
      
      // Send email notification
      if (submissionDetails) {
        await sendApprovalNotification(
          submissionDetails,
          'approved',
          comments
        );
      }
      
      setSuccess("Submission approved successfully!");
      
      // Notify parent component of the change
      if (onStatusChange) {
        onStatusChange();
      }
    } catch (err) {
      console.error("Error approving submission:", err);
      setError("Failed to approve submission.");
    } finally {
      setProcessing(false);
    }
  };
  
  const handleReject = async () => {
    if (!event?.extendedProps?.submissionId || !comments.trim()) {
      setError("Comments are required for rejection.");
      return;
    }
    
    setProcessing(true);
    setError(null);
    setSuccess(null);
    
    try {
      // Update approval status
      const success = await updateApprovalStatus(
        event.extendedProps.submissionId,
        'rejected',
        comments
      );
      
      if (!success) {
        throw new Error("Failed to reject submission");
      }
      
      // Send email notification
      if (submissionDetails) {
        await sendApprovalNotification(
          submissionDetails,
          'rejected',
          comments
        );
      }
      
      setSuccess("Submission rejected successfully.");
      
      // Notify parent component of the change
      if (onStatusChange) {
        onStatusChange();
      }
    } catch (err) {
      console.error("Error rejecting submission:", err);
      setError("Failed to reject submission.");
    } finally {
      setProcessing(false);
    }
  };
  
  if (!event) return null;
  
  // Format dates for display
  const startDate = event.start ? format(new Date(event.start), 'PPP') : 'Unknown';
  const endDate = event.end ? format(new Date(event.end), 'PPP') : startDate;
  const statusColor = getStatusColor(event.extendedProps?.status || 'pending');
  
  return (
    <Dialog 
      open={open} 
      onClose={() => !processing && onClose()}
      maxWidth="sm"
      fullWidth
      PaperProps={{ 
        sx: { 
          borderRadius: 2,
          background: `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.background.default})`
        } 
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        pb: 1
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Event sx={{ mr: 1, color: theme.palette.primary.main }} />
          <Typography variant="h6">
            Calendar Event Details
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose} 
          disabled={processing}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ pt: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error && (
              <Box sx={{ mb: 2, p: 1, bgcolor: 'error.light', borderRadius: 1 }}>
                <Typography color="error">{error}</Typography>
              </Box>
            )}
            
            {success && (
              <Box sx={{ mb: 2, p: 1, bgcolor: 'success.light', borderRadius: 1 }}>
                <Typography color="success.dark">{success}</Typography>
              </Box>
            )}
            
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                {event.title || 'Untitled Event'}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {event.extendedProps?.description || 'No description available'}
              </Typography>
              
              <Chip
                label={event.extendedProps?.status || 'pending'}
                size="small"
                sx={{ 
                  bgcolor: statusColor,
                  color: '#fff',
                  textTransform: 'capitalize',
                  mb: 2
                }}
              />
              
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2">
                  <strong>Date Range:</strong> {startDate} to {endDate}
                </Typography>
                <Typography variant="body2">
                  <strong>Submitted By:</strong> {event.extendedProps?.email || 'Unknown'}
                </Typography>
                <Typography variant="body2">
                  <strong>Form:</strong> {event.extendedProps?.formTitle || 'Unknown Form'}
                </Typography>
                <Typography variant="body2">
                  <strong>Question:</strong> {event.extendedProps?.questionText || 'Date Question'}
                </Typography>
              </Box>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            {event.extendedProps?.status === 'pending' ? (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  Approval Comments:
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  placeholder="Add comments about this approval/rejection..."
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  disabled={processing}
                  variant="outlined"
                  size="small"
                  sx={{ mb: 2 }}
                />
              </>
            ) : (
              <>
                <Typography variant="subtitle2" gutterBottom>
                  Comments:
                </Typography>
                <Typography variant="body2" sx={{ 
                  fontStyle: 'italic', 
                  p: 2, 
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'divider'
                }}>
                  {submissionDetails?.approvalComments || 'No comments provided'}
                </Typography>
              </>
            )}
          </>
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button 
          onClick={onClose} 
          disabled={processing}
          color="inherit"
        >
          Close
        </Button>
        
        {event.extendedProps?.status === 'pending' && !success && (
          <>
            <Button 
              onClick={handleReject}
              color="error"
              variant="outlined"
              disabled={processing || loading}
              startIcon={<Cancel />}
            >
              {processing ? "Processing..." : "Reject"}
            </Button>
            <Button 
              onClick={handleApprove}
              color="success"
              variant="contained"
              disabled={processing || loading}
              startIcon={<CheckCircle />}
            >
              {processing ? "Processing..." : "Approve"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EventDetailsModal;