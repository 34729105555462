import { format } from 'date-fns';

interface PDFHeaderProps {
  doc: any;
  form: any;
  formId: string | undefined;
  submissionId?: string;  // Add submissionId
  responses: { [key: string]: any };
  logoUrl: string | null;
  primaryColorRgb: [number, number, number];
  lightGrayColor: [number, number, number];
  blackTextColor: [number, number, number];
}

export const PDFHeader = ({
  doc,
  form,
  formId,
  responses,
  logoUrl,
  primaryColorRgb,
  lightGrayColor,
  blackTextColor
}: PDFHeaderProps): number => {
  
  // Add header with form title and date
  doc.setFillColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
  doc.rect(0, 0, doc.internal.pageSize.getWidth(), 22, "F");
  
  // Add title
  doc.setFont("helvetica", "bold");
  doc.setTextColor(255, 255, 255);
  doc.setFontSize(16);
  doc.text(form.formTitle || "Filled Form", 10, 15);
  doc.setTextColor(0, 0, 0);
  
  // Add date
  const today = format(new Date(), 'dd MMMM yyyy');
  
  // If company logo is available, add it. Otherwise show company name
  if (logoUrl && logoUrl.startsWith('data:image')) {
    // Position the logo on the right side
    const logoWidth = 50;
    const logoHeight = 25;
    const logoX = doc.internal.pageSize.getWidth() - logoWidth - 10;
    const logoY = 30;
    doc.addImage(logoUrl, "JPEG", logoX, logoY, logoWidth, logoHeight);
  } else if (form.companyName) {
    // Show company name instead of logo
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.setTextColor(primaryColorRgb[0], primaryColorRgb[1], primaryColorRgb[2]);
    const companyNameX = doc.internal.pageSize.getWidth() - 10;
    doc.text(form.companyName, companyNameX, 40, { align: "right" });
    doc.setTextColor(0, 0, 0);
  }
  
  // Add form information section - moved to left side and sized properly
  doc.setDrawColor(lightGrayColor[0], lightGrayColor[1], lightGrayColor[2]);
  doc.setFillColor(250, 250, 250);
  doc.roundedRect(10, 30, 100, 42, 3, 3, "FD"); // Increased height to fit all text including submission ID
  
  // Add form info text
  doc.setTextColor(blackTextColor[0], blackTextColor[1], blackTextColor[2]);
  doc.setFontSize(8); // Smaller font
  doc.text(`Form Slug: ${form.slug || formId}`, 15, 38);
  doc.text(`Submitted on: ${today}`, 15, 54);
  doc.text(`Submitted by: ${responses.email || "Anonymous"}`, 15, 62);
  
  // Return the current Y position after the header
  return 80; // Increased to accommodate the extra text
};