import { db } from '../../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  orderBy,
  limit,
  writeBatch,
  Timestamp,
  startAfter,
  startAt,
  getCountFromServer
} from 'firebase/firestore';

/**
 * Interface for submissions requiring approval
 */
export interface ApprovalSubmission {
  id: string;
  formId: string;
  formTitle?: string;
  formLogo?: string;
  email: string;
  submittedAt: any;
  approvalRequired: boolean;
  approvalStatus: 'pending' | 'approved' | 'rejected';
  approvalComments?: string;
  approvalDate?: any;
  userId?: string;
  [key: string]: any;
}

/**
 * Get all submissions requiring approval for a specific user
 * For admins, can retrieve all submissions or filter by user
 */
export const getApprovalsForUser = async (
  userId: string,
  status: 'pending' | 'approved' | 'rejected',
  isAdmin: boolean = false,
  filterByUserId: string | null = null,
  pageSize: number = 10,
  pageNumber: number = 0
): Promise<{
  submissions: ApprovalSubmission[];
  totalCount: number;
}> => {
  try {
    console.log(`Fetching ${status} approvals for ${isAdmin ? 'admin' : 'user'} ${userId}, page ${pageNumber}, size ${pageSize}`);
    
    // First get the forms that require approval
    let formsQuery;
    
    if (isAdmin && filterByUserId) {
      formsQuery = query(
        collection(db, "forms"),
        where("userId", "==", filterByUserId),
        where("requiresApproval", "==", true)
      );
    } else if (isAdmin && !filterByUserId) {
      formsQuery = query(
        collection(db, "forms"),
        where("requiresApproval", "==", true)
      );
    } else {
      formsQuery = query(
        collection(db, "forms"),
        where("userId", "==", userId),
        where("requiresApproval", "==", true)
      );
    }
    
    const formsSnapshot = await getDocs(formsQuery);
    
    if (formsSnapshot.empty) {
      console.log("No forms found requiring approval");
      return { submissions: [], totalCount: 0 };
    }
    
    const formIds = formsSnapshot.docs.map(doc => doc.id);
    console.log(`Found ${formIds.length} forms requiring approval`);
    
    // Create a map of form IDs to form titles and other form data
    const formDataMap: Record<string, any> = {};
    formsSnapshot.docs.forEach(doc => {
      formDataMap[doc.id] = {
        formTitle: doc.data().formTitle || 'Untitled Form',
        formLogo: doc.data().companyLogo,
        userId: doc.data().userId
      };
    });
    
    // Build the query for submissions
    let submissionsQuery;
    
    // Use a different approach if we need to paginate
    if (pageNumber > 0) {
      // For pagination, use the startAfter approach
      // First get the last document from the previous page
      const prevPageQuery = query(
        collection(db, "submission_details"),
        where("formId", "in", formIds.length > 10 ? formIds.slice(0, 10) : formIds),
        where("approvalStatus", "==", status),
        where("archived", "==", false),
        orderBy("submittedAt", "desc"),
        limit(pageSize * pageNumber)
      );
      
      const prevPageSnapshot = await getDocs(prevPageQuery);
      
      if (prevPageSnapshot.empty) {
        return { submissions: [], totalCount: 0 };
      }
      
      // Get the last document as the starting point
      const lastDoc = prevPageSnapshot.docs[prevPageSnapshot.size - 1];
      
      // Now get the current page
      submissionsQuery = query(
        collection(db, "submission_details"),
        where("formId", "in", formIds.length > 10 ? formIds.slice(0, 10) : formIds),
        where("approvalStatus", "==", status),
        where("archived", "==", false),
        orderBy("submittedAt", "desc"),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      // First page, simpler query
      submissionsQuery = query(
        collection(db, "submission_details"),
        where("formId", "in", formIds.length > 10 ? formIds.slice(0, 10) : formIds),
        where("approvalStatus", "==", status),
        where("archived", "==", false),
        orderBy("submittedAt", "desc"),
        limit(pageSize)
      );
    }
    
    const submissionsSnapshot = await getDocs(submissionsQuery);
    console.log(`Found ${submissionsSnapshot.size} submissions`);
    
    // Handle the case where we have more than 10 forms (Firestore limitation)
    let additionalSubmissions: any[] = [];
    if (formIds.length > 10) {
      // Process additional form IDs in batches of 10
      for (let i = 10; i < formIds.length; i += 10) {
        const batchIds = formIds.slice(i, i + 10);
        const batchQuery = query(
          collection(db, "submission_details"),
          where("formId", "in", batchIds),
          where("approvalStatus", "==", status),
          where("archived", "==", false),
          orderBy("submittedAt", "desc"),
          limit(pageSize)
        );
        
        const batchSnapshot = await getDocs(batchQuery);
        additionalSubmissions = [...additionalSubmissions, ...batchSnapshot.docs];
      }
      
      console.log(`Found ${additionalSubmissions.length} additional submissions`);
    }
    
    // Combine and sort all submissions by date
    const allDocs = [...submissionsSnapshot.docs, ...additionalSubmissions];
    allDocs.sort((a, b) => {
      const dateA = a.data().submittedAt?.toMillis?.() || 0;
      const dateB = b.data().submittedAt?.toMillis?.() || 0;
      return dateB - dateA; // Descending order (newest first)
    });
    
    // Limit to pageSize
    const paginatedDocs = allDocs.slice(0, pageSize);
    
    // Map submissions with proper form titles
    const submissions = paginatedDocs.map(doc => {
      const data = doc.data();
      const formId = data.formId;
      const formData = formDataMap[formId] || {};
      
      return {
        id: doc.id,
        formId: formId,
        // Use the form title from the forms collection
        formTitle: formData.formTitle || data.formTitle || 'Untitled Form',
        formLogo: formData.formLogo,
        email: data.email || '',
        submittedAt: data.submittedAt,
        approvalRequired: data.approvalRequired,
        approvalStatus: data.approvalStatus,
        approvalComments: data.approvalComments || '',
        approvalDate: data.approvalDate,
        userId: data.userId || formData.userId
      } as ApprovalSubmission;
    });
    
    // Get total count (for pagination display)
    let totalCount = 0;
    for (const formId of formIds) {
      const formData = formDataMap[formId];
      const count = status === 'pending' 
        ? (formData.pendingCount || 0) 
        : status === 'approved' 
        ? (formData.approvedCount || 0) 
        : (formData.rejectedCount || 0);
      totalCount += count;
    }
    
    return { submissions, totalCount };
  } catch (error) {
    console.error('Error fetching approvals:', error);
    return { submissions: [], totalCount: 0 };
  }
};

/**
 * Helper function to fix submissions missing approval fields
 * This runs in the background and doesn't block the main operation
 */
async function fixMissingApprovalFields(submissions: Array<{id: string, formId: string}>) {
  try {
    // Process in batches to avoid Firestore limits
    const BATCH_SIZE = 450;
    for (let i = 0; i < submissions.length; i += BATCH_SIZE) {
      const batch = writeBatch(db);
      const batchItems = submissions.slice(i, i + BATCH_SIZE);
      
      for (const submission of batchItems) {
        // CRITICAL CHANGE: Use submission_details collection
        const submissionRef = doc(db, "submission_details", submission.id);
        const submissionDoc = await getDoc(submissionRef);
        
        if (submissionDoc.exists()) {
          const data = submissionDoc.data();
          const updateData: Record<string, any> = {};
          
          // Set approvalRequired to true
          if (data.approvalRequired !== true) {
            updateData.approvalRequired = true;
          }
          
          // If no approvalStatus exists, set it to 'pending'
          if (!data.approvalStatus) {
            updateData.approvalStatus = 'pending';
          }
          
          // If approvalComments is missing, add an empty string
          if (!data.hasOwnProperty('approvalComments')) {
            updateData.approvalComments = '';
          }
          
          if (Object.keys(updateData).length > 0) {
            batch.update(submissionRef, updateData);
          }
        }
      }
      
      await batch.commit();
      console.log(`Fixed ${batchItems.length} submissions in batch ${Math.floor(i/BATCH_SIZE) + 1}`);
    }
  } catch (error) {
    console.error('Error fixing missing approval fields:', error);
    throw error;
  }
}

/**
 * Fix submissions missing the archived field
 * @param progressCallback Optional callback to report progress
 */
export const fixMissingArchivedField = async (
  progressCallback?: (message: string) => void
): Promise<{ 
  processed: number;
  updated: number;
  formsProcessed: number;
  submissionsScanned: number;
  submissionsFixed: number;
  errors: number;
}> => {
  try {
    // Initialize counters
    let processed = 0;
    let updated = 0;
    let errors = 0;
    let formsProcessed = 0;
    
    // First, find all forms that require approval
    if (progressCallback) progressCallback("🔍 Looking for forms that require approval...");
    
    const formsQuery = query(
      collection(db, "forms"),
      where("requiresApproval", "==", true)
    );
    
    const formsSnapshot = await getDocs(formsQuery);
    if (formsSnapshot.empty) {
      if (progressCallback) progressCallback("❌ No forms found requiring approval");
      return { 
        processed: 0, 
        updated: 0, 
        formsProcessed: 0, 
        submissionsScanned: 0, 
        submissionsFixed: 0, 
        errors: 0 
      };
    }
    
    const formIds = formsSnapshot.docs.map(doc => doc.id);
    formsProcessed = formIds.length;
    
    if (progressCallback) progressCallback(`✅ Found ${formIds.length} forms with approval required`);
    
    // Process each form
    let totalSubmissionsScanned = 0;
    let totalSubmissionsFixed = 0;
    
    for (let i = 0; i < formIds.length; i++) {
      const formId = formIds[i];
      const formDoc = await getDoc(doc(db, "forms", formId));
      const formTitle = formDoc.exists() ? formDoc.data().formTitle || 'Untitled Form' : formId;
      
      if (progressCallback) progressCallback(`📊 Processing form ${i+1}/${formIds.length}: "${formTitle}" (${formId})`);
      
      // Query submissions for this form
      const submissionsQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", formId)
      );
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      const submissionsCount = submissionsSnapshot.size;
      totalSubmissionsScanned += submissionsCount;
      
      if (progressCallback) progressCallback(`📋 Found ${submissionsCount} submissions for form "${formTitle}"`);
      
      if (submissionsCount === 0) continue;
      
      // Find submissions without archived field
      const submissionsToUpdate = submissionsSnapshot.docs.filter(doc => {
        const data = doc.data();
        return data.archived === undefined;
      });
      
      if (progressCallback) progressCallback(`🔄 Found ${submissionsToUpdate.length} submissions missing archived field`);
      
      if (submissionsToUpdate.length === 0) continue;
      
      // Process in batches
      const batchSize = 450;
      let batchCount = 0;
      
      for (let j = 0; j < submissionsToUpdate.length; j += batchSize) {
        const batch = writeBatch(db);
        const batchItems = submissionsToUpdate.slice(j, j + batchSize);
        batchCount++;
        
        for (const docSnapshot of batchItems) {
          try {
            // Update submission_details
            batch.update(docSnapshot.ref, { archived: false });
            processed++;
            
            // Also update form_submissions if it exists
            const formSubmissionRef = doc(db, "form_submissions", docSnapshot.id);
            const formSubmissionDoc = await getDoc(formSubmissionRef);
            
            if (formSubmissionDoc.exists()) {
              const formSubmissionData = formSubmissionDoc.data();
              if (formSubmissionData.archived === undefined) {
                // Use a separate batch for form_submissions to avoid transaction issues
                const formSubmissionBatch = writeBatch(db);
                formSubmissionBatch.update(formSubmissionRef, { archived: false });
                await formSubmissionBatch.commit();
              }
            }
            
            updated++;
          } catch (error) {
            console.error(`Error updating submission ${docSnapshot.id}:`, error);
            errors++;
          }
        }
        
        // Commit the batch
        await batch.commit();
        totalSubmissionsFixed += batchItems.length;
        
        if (progressCallback) {
          const progress = Math.round(((i / formIds.length) + (batchCount / Math.ceil(submissionsToUpdate.length / batchSize)) / formIds.length) * 100);
          progressCallback(`✓ Processed batch ${batchCount} for form "${formTitle}" (${progress}% complete)`);
        }
      }
    }
    
    return { 
      processed, 
      updated, 
      formsProcessed, 
      submissionsScanned: totalSubmissionsScanned, 
      submissionsFixed: totalSubmissionsFixed, 
      errors 
    };
  } catch (error) {
    console.error("Error fixing archived field:", error);
    throw error;
  }
};

/**
 * Get all forms that require approval with their submission counts
 */
export const getApprovalFormSummary = async (
  userId: string,
  isAdmin: boolean = false,
  filterByUserId: string | null = null
): Promise<Array<{
  formId: string;
  formTitle: string;
  formLogo?: string;
  userId: string;
  pendingCount: number;
  approvedCount: number;
  rejectedCount: number;
}>> => {
  try {
    // Base query filters
    const baseFilters = [
      where("archived", "==", false) // Ensure this line exists to exclude archived submissions
    ];
    
    // Add user-specific filters based on permissions
    if (!isAdmin) {
      // Regular user can only see their own forms
      baseFilters.push(where("userId", "==", userId));
    } else if (filterByUserId) {
      // Admin filtering by specific user
      baseFilters.push(where("userId", "==", filterByUserId));
    }
    
    // For each status, run a separate query for better performance
    // Pending submissions
    const pendingQuery = query(
      collection(db, "submission_details"),
      ...baseFilters,
      where("approvalStatus", "==", "pending")
    );
    
    // Approved submissions
    const approvedQuery = query(
      collection(db, "submission_details"),
      ...baseFilters,
      where("approvalStatus", "==", "approved")
    );
    
    // Rejected submissions
    const rejectedQuery = query(
      collection(db, "submission_details"),
      ...baseFilters,
      where("approvalStatus", "==", "rejected")
    );
    
    // Execute all queries in parallel
    const [pendingSnapshot, approvedSnapshot, rejectedSnapshot] = await Promise.all([
      getDocs(pendingQuery),
      getDocs(approvedQuery),
      getDocs(rejectedQuery)
    ]);
    
    // Process results
    const formSummary = new Map();
    
    // Process pending submissions
    pendingSnapshot.docs.forEach(doc => {
      const data = doc.data();
      const formId = data.formId;
      
      if (!formSummary.has(formId)) {
        formSummary.set(formId, {
          formId,
          formTitle: data.formTitle || 'Untitled Form',
          formLogo: data.formLogo,
          userId: data.userId,
          pendingCount: 1,
          approvedCount: 0,
          rejectedCount: 0
        });
      } else {
        formSummary.get(formId).pendingCount += 1;
      }
    });
    
    // Process approved submissions
    approvedSnapshot.docs.forEach(doc => {
      const data = doc.data();
      const formId = data.formId;
      
      if (!formSummary.has(formId)) {
        formSummary.set(formId, {
          formId,
          formTitle: data.formTitle || 'Untitled Form',
          formLogo: data.formLogo,
          userId: data.userId,
          pendingCount: 0,
          approvedCount: 1,
          rejectedCount: 0
        });
      } else {
        formSummary.get(formId).approvedCount += 1;
      }
    });
    
    // Process rejected submissions
    rejectedSnapshot.docs.forEach(doc => {
      const data = doc.data();
      const formId = data.formId;
      
      if (!formSummary.has(formId)) {
        formSummary.set(formId, {
          formId,
          formTitle: data.formTitle || 'Untitled Form',
          formLogo: data.formLogo,
          userId: data.userId,
          pendingCount: 0,
          approvedCount: 0,
          rejectedCount: 1
        });
      } else {
        formSummary.get(formId).rejectedCount += 1;
      }
    });
    
    // Convert Map to Array and return
    return Array.from(formSummary.values());
  } catch (error) {
    console.error("Error getting approval form summary:", error);
    return [];
  }
};

/**
 * Updates approval status for a submission
 */
export const updateApprovalStatus = async (
  submissionId: string,
  status: 'approved' | 'rejected',
  comments: string
): Promise<boolean> => {
  try {
    // CRITICAL CHANGE: Use submission_details collection
    const submissionRef = doc(db, "submission_details", submissionId);
    
    const updateData = {
      approvalStatus: status,
      approvalComments: comments,
      approvalDate: Timestamp.now(),
      approvalRequired: true // Always ensure this is set to true
    };
    
    // Update the submission
    await updateDoc(submissionRef, updateData);
    
    return true;
  } catch (error) {
    console.error(`Error updating approval status for submission ${submissionId}:`, error);
    return false;
  }
};

/**
 * Gets detailed submission data for viewing
 */
export const getApprovalSubmissionDetails = async (submissionId: string) => {
  try {
    const submissionRef = doc(db, "submission_details", submissionId);
    const submissionSnap = await getDoc(submissionRef);
    
    if (!submissionSnap.exists()) {
      throw new Error(`Submission ${submissionId} not found`);
    }
    
    const submissionData = submissionSnap.data();
    
    // Check if submission is archived and log warning if it is
    if (submissionData.archived) {
      console.warn(`Accessing archived submission: ${submissionId}`);
    }
    
    // Get form questions
    const formId = submissionData.formId;
    let questions: any[] = [];
    
    if (formId) {
      const formRef = doc(db, "forms", formId);
      const formSnap = await getDoc(formRef);
      
      if (formSnap.exists()) {
        questions = formSnap.data().questions || [];
      }
    }
    
    return {
      submission: submissionData,
      questions
    };
  } catch (error) {
    console.error("Error getting submission details:", error);
    throw error;
  }
};

/**
 * Get submissions for a specific form with pagination
 */
export const getApprovalSubmissionsForForm = async (
  userId: string,
  formId: string,
  status: 'pending' | 'approved' | 'rejected',
  isAdmin: boolean = false,
  filterUserId: string | null = null,
  pageSize: number = 10,
  page: number = 0
): Promise<any[]> => {
  try {
    // Base query with archived filter
    let baseQuery;
    
    if (isAdmin && filterUserId) {
      baseQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", formId),
        where("approvalStatus", "==", status),
        where("userId", "==", filterUserId),
        where("archived", "==", false)
      );
    } else if (isAdmin) {
      baseQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", formId),
        where("approvalStatus", "==", status),
        where("archived", "==", false)
      );
    } else {
      baseQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", formId),
        where("approvalStatus", "==", status),
        where("userId", "==", userId),
        where("archived", "==", false)
      );
    }
    
    // Add sorting and pagination
    const submissionsQuery = query(
      baseQuery,
      orderBy("submittedAt", "desc"),
      limit(pageSize),
      page > 0 ? startAfter(page * pageSize) : startAt(0)
    );

    try {
      const submissionsSnapshot = await getDocs(submissionsQuery);
      return submissionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.warn("Error with full query, trying fallback:", error);
      
      // Fallback if the archived index isn't working
      let fallbackQuery;
      
      if (isAdmin && filterUserId) {
        fallbackQuery = query(
          collection(db, "submission_details"),
          where("formId", "==", formId),
          where("approvalStatus", "==", status),
          where("userId", "==", filterUserId),
          orderBy("submittedAt", "desc"),
          limit(pageSize * 2) // Get more to filter client-side
        );
      } else if (isAdmin) {
        fallbackQuery = query(
          collection(db, "submission_details"),
          where("formId", "==", formId),
          where("approvalStatus", "==", status),
          orderBy("submittedAt", "desc"),
          limit(pageSize * 2)
        );
      } else {
        fallbackQuery = query(
          collection(db, "submission_details"),
          where("formId", "==", formId),
          where("approvalStatus", "==", status),
          where("userId", "==", userId),
          orderBy("submittedAt", "desc"),
          limit(pageSize * 2)
        );
      }
      
      const fallbackSnapshot = await getDocs(fallbackQuery);
      
      // Filter non-archived and apply pagination client-side
      return fallbackSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() } as { id: string; archived?: boolean; [key: string]: any }))
        .filter(doc => doc.archived !== true)
        .slice(0, pageSize);
    }
  } catch (error) {
    console.error("Error getting form submissions:", error);
    return [];
  }
};

/**
 * Debugging function to find all recent submissions with pending approval
 */
export const findMissingPendingApprovals = async (): Promise<any[]> => {
  try {
    // Use the existing db instance from imports
    
    // Get submissions from the last 7 days
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    // CRITICAL CHANGE: Use submission_details collection
    const pendingQuery = query(
      collection(db, "submission_details"),
      where("approvalStatus", "==", "pending")
    );
    
    const pendingSnapshot = await getDocs(pendingQuery);
    console.log(`Found ${pendingSnapshot.size} submissions with pending approval status`);
    
    // Get detailed information about each submission
    const submissions = pendingSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        formId: data.formId,
        userId: data.userId,
        email: data.email,
        submittedAt: data.submittedAt,
        approvalStatus: data.approvalStatus,
        approvalRequired: data.approvalRequired,
        approvalComments: data.approvalComments,
        archived: data.archived,
        formTitle: data.formTitle
      };
    });
    
    return submissions;
  } catch (error) {
    console.error("Error finding missing approvals:", error);
    throw error;
  }
};

/**
 * Check both possible collection names for submissions
 */
export const checkSubmissionCollections = async (): Promise<{
  form_submissions: number;
  formSubmissions: number;
  submission_details: number;
  mismatchedCollections: boolean;
}> => {
  try {
    // Use the existing db instance from imports
    
    // Check snake_case collection
    const snakeQuery = query(
      collection(db, "form_submissions"),
      limit(1)
    );
    
    // Check camelCase collection
    const camelQuery = query(
      collection(db, "formSubmissions"),
      limit(1)
    );
    
    // CRITICAL ADDITION: Check submission_details collection
    const detailsQuery = query(
      collection(db, "submission_details"),
      limit(1)
    );
    
    // Get counts from all collections
    const [snakeSnapshot, camelSnapshot, detailsSnapshot] = await Promise.all([
      getDocs(snakeQuery).catch(() => ({ empty: true })),
      getDocs(camelQuery).catch(() => ({ empty: true })),
      getDocs(detailsQuery).catch(() => ({ empty: true }))
    ]);
    
    // Check if collections exist
    const snakeExists = !snakeSnapshot.empty;
    const camelExists = !camelSnapshot.empty;
    const detailsExists = !detailsSnapshot.empty;
    
    // Get total counts if the collections exist
    let snakeCount = 0;
    let camelCount = 0;
    let detailsCount = 0;
    
    if (snakeExists) {
      const countSnapshot = await getCountFromServer(collection(db, "form_submissions"));
      snakeCount = countSnapshot.data().count;
    }
    
    if (camelExists) {
      const countSnapshot = await getCountFromServer(collection(db, "formSubmissions"));
      camelCount = countSnapshot.data().count;
    }
    
    if (detailsExists) {
      const countSnapshot = await getCountFromServer(collection(db, "submission_details"));
      detailsCount = countSnapshot.data().count;
    }
    
    return {
      form_submissions: snakeCount,
      formSubmissions: camelCount,
      submission_details: detailsCount,
      mismatchedCollections: (snakeExists && camelExists) || (snakeExists && detailsExists) || (camelExists && detailsExists)
    };
  } catch (error) {
    console.error("Error checking submission collections:", error);
    throw error;
  }
};

/**
 * Check a specific submission by ID
 */
export const checkSpecificSubmission = async (
  submissionId: string
): Promise<{
  exists: boolean;
  inFormSubmissions: boolean;
  inSubmissionDetails: boolean;
  formExists: boolean;
  formTitle: string;
  approvalStatus: string | null;
  approvalRequired: boolean | null;
  archived: boolean | null;
  submittedAt: any;
  email: string | null;
  formId: string | null;
  issues: string[];
}> => {
  try {
    // Use the imported db instance
    const issues: string[] = [];
    
    // Default result structure
    const result = {
        exists: false,
        inFormSubmissions: false,
        inSubmissionDetails: false,
        formExists: false,
        formTitle: "Unknown",
        approvalStatus: null,
        approvalRequired: null,
        archived: null as boolean | null,
        submittedAt: null,
        email: null,
        formId: null,
        issues: issues
      };
    
    // Check in form_submissions collection
    const formSubmissionRef = doc(db, "form_submissions", submissionId);
    const formSubmissionDoc = await getDoc(formSubmissionRef);
    
    if (formSubmissionDoc.exists()) {
      result.inFormSubmissions = true;
      result.exists = true;
      
      const formSubmissionData = formSubmissionDoc.data();
      result.approvalStatus = formSubmissionData.approvalStatus || null;
      result.approvalRequired = formSubmissionData.approvalRequired || null;
      result.archived = formSubmissionData.archived === true;
      result.submittedAt = formSubmissionData.submittedAt;
      result.email = formSubmissionData.email || null;
      result.formId = formSubmissionData.formId || null;
      
      // Check for issues
      if (result.formId === null) {
        issues.push("Missing formId in form_submissions");
      }
      
      if (result.approvalStatus !== "pending" && 
          result.approvalStatus !== "approved" && 
          result.approvalStatus !== "rejected") {
        issues.push(`Invalid approval status: ${result.approvalStatus}`);
      }
      
      if (result.approvalRequired !== true) {
        issues.push("approvalRequired not set to true");
      }
      
      if (result.archived === true) {
        issues.push("Submission is archived");
      }
    }
    
    // Check in submission_details collection
    const detailsRef = doc(db, "submission_details", submissionId);
    const detailsDoc = await getDoc(detailsRef);
    
    if (detailsDoc.exists()) {
      result.inSubmissionDetails = true;
      result.exists = true;
      
      const detailsData = detailsDoc.data();
      
      // If we didn't find it in form_submissions, use data from submission_details
      if (!result.inFormSubmissions) {
        result.approvalStatus = detailsData.approvalStatus || null;
        result.approvalRequired = detailsData.approvalRequired || null;
        result.archived = detailsData.archived === true;
        result.submittedAt = detailsData.submittedAt;
        result.email = detailsData.email || null;
        result.formId = detailsData.formId || null;
      }
      
      // Check for discrepancies between the two collections
      if (result.inFormSubmissions) {
        if (result.formId !== detailsData.formId) {
          issues.push(`formId mismatch: ${result.formId} vs ${detailsData.formId}`);
        }
        
        if (result.approvalStatus !== detailsData.approvalStatus) {
          issues.push(`approvalStatus mismatch: ${result.approvalStatus} vs ${detailsData.approvalStatus}`);
        }
        
        if (result.approvalRequired !== detailsData.approvalRequired) {
          issues.push(`approvalRequired mismatch: ${result.approvalRequired} vs ${detailsData.approvalRequired}`);
        }
        
        if (result.archived !== (detailsData.archived === true)) {
          issues.push(`archived mismatch: ${result.archived} vs ${detailsData.archived}`);
        }
      } else {
        // Check for issues in submission_details
        if (result.formId === null) {
          issues.push("Missing formId in submission_details");
        }
        
        if (result.approvalStatus !== "pending" && 
            result.approvalStatus !== "approved" && 
            result.approvalStatus !== "rejected") {
          issues.push(`Invalid approval status: ${result.approvalStatus}`);
        }
        
        if (result.approvalRequired !== true) {
          issues.push("approvalRequired not set to true");
        }
        
        if (result.archived === true) {
          issues.push("Submission is archived");
        }
      }
    }
    
    // If we have a formId, check if the form exists and get its title
    if (result.formId) {
      const formRef = doc(db, "forms", result.formId);
      const formDoc = await getDoc(formRef);
      
      if (formDoc.exists()) {
        result.formExists = true;
        result.formTitle = formDoc.data().formTitle || "Untitled Form";
        
        // Check if the form requires approval
        if (formDoc.data().requiresApproval !== true) {
          issues.push("Form does not have requiresApproval set to true");
        }
      } else {
        issues.push(`Form with ID ${result.formId} does not exist`);
      }
    }
    
    result.issues = issues;
    return result;
  } catch (error) {
    console.error(`Error checking submission ${submissionId}:`, error);
    return {
      exists: false,
      inFormSubmissions: false,
      inSubmissionDetails: false,
      formExists: false,
      formTitle: "Error",
      approvalStatus: null,
      approvalRequired: null,
      archived: null,
      submittedAt: null,
      email: null,
      formId: null,
      issues: [(error instanceof Error) ? error.message : "Unknown error"]
    };
  }
};

/**
 * Fix a specific submission by ID
 */
export const fixSpecificSubmission = async (
  submissionId: string
): Promise<{
  success: boolean;
  message: string;
  updatedFields: Record<string, any>;
}> => {
  try {
    // Use the imported db instance
    const updatedFields: Record<string, any> = {};
    
    // First check the current status
    const submissionInfo = await checkSpecificSubmission(submissionId);
    
    if (!submissionInfo.exists) {
      return { 
        success: false, 
        message: "Submission does not exist", 
        updatedFields 
      };
    }
    
    if (!submissionInfo.formId) {
      return { 
        success: false, 
        message: "Submission has no formId",
        updatedFields 
      };
    }
    
    // Get the form to ensure we have the title
    const formRef = doc(db, "forms", submissionInfo.formId);
    const formDoc = await getDoc(formRef);
    
    if (!formDoc.exists()) {
      return { 
        success: false, 
        message: `Form with ID ${submissionInfo.formId} does not exist`, 
        updatedFields 
      };
    }
    
    const formData = formDoc.data();
    
    // Fix missing fields
    if (submissionInfo.approvalRequired !== true) {
      updatedFields.approvalRequired = true;
    }
    
    if (!submissionInfo.approvalStatus) {
      updatedFields.approvalStatus = "pending";
    }
    
    if (submissionInfo.archived === null) {
      updatedFields.archived = false;
    }
    
    if (Object.keys(updatedFields).length > 0) {
      const submissionRef = doc(db, "submission_details", submissionId);
      await updateDoc(submissionRef, updatedFields);
    }
    
    return { 
      success: true, 
      message: "Submission fixed successfully", 
      updatedFields 
    };
  } catch (error) {
    console.error(`Error fixing submission ${submissionId}:`, error);
    return { 
      success: false, 
      message: (error instanceof Error) ? error.message : "Unknown error", 
      updatedFields: {} 
    };
  }
};

/**
 * Debug function to check all submissions for a specific form
 */
export const checkAllSubmissionsForForm = async (
  formId: string
): Promise<{
  inSubmissionDetails: any[];
  inFormSubmissions: any[];
  mismatches: any[];
}> => {
  try {
    // Query all submissions in submission_details
    const detailsQuery = query(
      collection(db, "submission_details"),
      where("formId", "==", formId)
    );
    
    // Query all submissions in form_submissions
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    // Get submissions from both collections
    const [detailsSnapshot, submissionsSnapshot] = await Promise.all([
      getDocs(detailsQuery),
      getDocs(submissionsQuery)
    ]);
    
    // Convert to arrays with full data
    const detailsSubmissions = detailsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      collection: "submission_details"
    }));
    
    const formSubmissions = submissionsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      collection: "form_submissions"
    }));
    
    // Check for mismatches
    const detailsIds = new Set(detailsSubmissions.map(s => s.id));
    const formIds = new Set(formSubmissions.map(s => s.id));
    
    // Find submissions in form_submissions but not in submission_details
    const missingInDetails = formSubmissions.filter(s => !detailsIds.has(s.id));
    
    // Find submissions in submission_details but not in form_submissions
    const missingInForm = detailsSubmissions.filter(s => !formIds.has(s.id));
    
    // Define a proper type for the submission data with the properties we need
    type SubmissionData = {
      id: string;
      collection: string;
      approvalStatus?: string;
      approvalRequired?: boolean;
      archived?: boolean;
      [key: string]: any;
    };
    
    // Find submissions with different approval status
    const statusMismatches = detailsSubmissions
      .filter(d => formIds.has(d.id))
      .map(d => {
        const formSub = formSubmissions.find(f => f.id === d.id);
        if (!formSub) return null;
        
        // Type assertion to help TypeScript understand these objects
        const detailsData = d as SubmissionData;
        const formData = formSub as SubmissionData;
        
        const mismatches = [];
        if (detailsData.approvalStatus !== formData.approvalStatus) {
          mismatches.push(`approvalStatus: ${detailsData.approvalStatus} vs ${formData.approvalStatus}`);
        }
        if (detailsData.approvalRequired !== formData.approvalRequired) {
          mismatches.push(`approvalRequired: ${detailsData.approvalRequired} vs ${formData.approvalRequired}`);
        }
        if (detailsData.archived !== formData.archived) {
          mismatches.push(`archived: ${detailsData.archived} vs ${formData.archived}`);
        }
        
        if (mismatches.length > 0) {
          return {
            id: d.id,
            mismatches,
            submission_details: detailsData,
            form_submissions: formData
          };
        }
        return null;
      })
      .filter(Boolean);
    
    return {
      inSubmissionDetails: detailsSubmissions,
      inFormSubmissions: formSubmissions,
      mismatches: [
        ...missingInDetails.map(s => ({ 
          type: "Missing in submission_details", 
          submission: s as SubmissionData
        })),
        ...missingInForm.map(s => ({ 
          type: "Missing in form_submissions", 
          submission: s as SubmissionData 
        })),
        ...statusMismatches
      ]
    };
  } catch (error) {
    console.error(`Error checking submissions for form ${formId}:`, error);
    throw error;
  }
};

/**
 * Sync data between form_submissions and submission_details collections
 */
export const syncSubmissionCollections = async (
  formId: string
): Promise<{
  updated: number;
  created: number;
  errors: number;
}> => {
  try {
    // First check all submissions for this form
    const { inSubmissionDetails, inFormSubmissions, mismatches } = 
      await checkAllSubmissionsForForm(formId);
    
    console.log(`Found ${inSubmissionDetails.length} submissions in submission_details`);
    console.log(`Found ${inFormSubmissions.length} submissions in form_submissions`);
    console.log(`Found ${mismatches.length} mismatches between collections`);
    
    let updated = 0;
    let created = 0;
    let errors = 0;
    
    // Process in batches
    const BATCH_SIZE = 450;
    
    // 1. Fix submissions missing in submission_details
    const missingInDetails = mismatches
      .filter(m => m.type === "Missing in submission_details")
      .map(m => m.submission);
    
    for (let i = 0; i < missingInDetails.length; i += BATCH_SIZE) {
      const batch = writeBatch(db);
      const currentBatch = missingInDetails.slice(i, i + BATCH_SIZE);
      
      for (const submission of currentBatch) {
        // Make sure submission.collection and submission.id exist before using them
        if (submission && typeof submission === 'object' && 'id' in submission && 'collection' in submission) {
          // Use type assertion to tell TypeScript we know the structure
          const { id, collection, ...data } = submission as { 
            id: string; 
            collection: string; 
            [key: string]: any 
          };
          
          const detailsRef = doc(db, "submission_details", id);
          batch.set(detailsRef, data);
        }
      }
      
      await batch.commit();
      created += currentBatch.length;
    }
    
    // 2. Update submissions with mismatched approval status
    const statusMismatches = mismatches
      .filter(m => m.type === "Status mismatches");
    
    for (let i = 0; i < statusMismatches.length; i += BATCH_SIZE) {
      const batch = writeBatch(db);
      const currentBatch = statusMismatches.slice(i, i + BATCH_SIZE);
      
      for (const mismatch of currentBatch) {
        // Check if the mismatch has the expected structure
        if (mismatch && typeof mismatch === 'object' && 'id' in mismatch && 'submission_details' in mismatch) {
          const id = mismatch.id as string;
          const details = mismatch.submission_details as Record<string, any>;
          
          // Update the form_submissions document to match submission_details
          const formRef = doc(db, "form_submissions", id);
          
          const updateData = {
            approvalStatus: details.approvalStatus,
            approvalRequired: true,
            approvalComments: details.approvalComments || "",
            archived: details.archived === true
          };
          
          batch.update(formRef, updateData);
        }
      }
      
      await batch.commit();
      updated += currentBatch.length;
    }
    
    return { updated, created, errors };
  } catch (error) {
    console.error(`Error syncing collections for form ${formId}:`, error);
    throw error;
  }
};

